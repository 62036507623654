<template>
  <main class="greygoose">
    <div class="greygoose-banner">
      <div class="container desk">
        <div class="greygoose-banner__row">
          <div class="greygoose-banner__img">
            <img src="/greygoose/vodka.png" alt="">
          </div>
          <div class="greygoose-banner__info">
            <h1 v-html="$t('greygoose.banner.title')"></h1>
            <p>{{ $t("greygoose.banner.subtitle") }}</p>
            <button>{{ $t("greygoose.banner.btn") }}</button>
            <div class="flex">
              <div class="timer">
                <p>{{ $t("greygoose.banner.timer.title") }}</p>
                <div class="timer-items">
                  <div class="timer-item">
                    <p>{{ timer.days }}</p>
                    <span>{{ $t("greygoose.banner.timer.day") }}</span>
                  </div>
                  <div class="item">:</div>
                  <div class="timer-item">
                    <p>{{ timer.hours }}</p>
                    <span>{{ $t("greygoose.banner.timer.th") }}</span>
                  </div>
                  <div class="item">:</div>
                  <div class="timer-item">
                    <p>{{ timer.minutes }}</p>
                    <span>{{ $t("greygoose.banner.timer.min") }}</span>
                  </div>
                  <div class="item">:</div>
                  <div class="timer-item">
                    <p>{{ timer.seconds }}</p>
                    <span>{{ $t("greygoose.banner.timer.sec") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="greygoose-banner__stik">
            <img src="/greygoose/bstik.png" alt="">
          </div>
        </div>
        <div class="greygoose-banner__row ruls">
          <div class="greygoose-banner__img">
            <img src="/greygoose/logo.png" alt="">
          </div>
          <div class="greygoose-banner__text">
            <h2 class="black">{{ $t("greygoose.banner.secondTitle") }}</h2>
            <p>{{ $t("greygoose.banner.text1") }}</p>
            <p>{{ $t("greygoose.banner.text2") }}</p>
            <button>{{ $t("greygoose.banner.btn") }}</button>
          </div>
          
          <div class="greygoose-banner__img">
            <img src="/greygoose/pachet.png" alt="">
          </div>
        </div>
      </div>
      
      <div class="container mob">
        <div class="greygoose-banner__row-top">
          <div class="greygoose-banner__img">
            <img src="/greygoose/vodka.png" alt="">
          </div>
          <div class="greygoose-banner__info">
            <div class="greygoose-banner__stik">
              <img src="/greygoose/bstik.png" alt="">
            </div>
            <h1 v-html="$t('greygoose.banner.title')"></h1>
            <p>{{ $t("greygoose.banner.subtitle") }}</p>
            <button class="dark">{{ $t("greygoose.banner.btn") }}</button>
          </div>
        </div>
        
        <div class="timer">
          <p>{{ $t("greygoose.banner.timer.title") }}</p>
          <div class="timer-items">
            <div class="timer-item">
              <p>88</p>
              <span>{{ $t("greygoose.banner.timer.day") }}</span>
            </div>
            <div class="item">:</div>
            <div class="timer-item">
              <p>88</p>
              <span>{{ $t("greygoose.banner.timer.th") }}</span>
            </div>
            <div class="item">:</div>
            <div class="timer-item">
              <p>88</p>
              <span>{{ $t("greygoose.banner.timer.min") }}</span>
            </div>
            <div class="item">:</div>
            <div class="timer-item">
              <p>88</p>
              <span>{{ $t("greygoose.banner.timer.sec") }}</span>
            </div>
          </div>
        </div>
        <div class="greygoose-banner__img logo">
          <img src="/greygoose/logo.png" alt="">
        </div>
        <h2 class="black">{{ $t("greygoose.banner.secondTitle") }}</h2>
        <div class="greygoose-banner__row-bottom">
          <div class="greygoose-banner__text">
            <p class="first">{{ $t("greygoose.banner.text1") }}</p>
            <p>{{ $t("greygoose.banner.text2") }}</p>
            <button>{{ $t("greygoose.banner.btn") }}</button>
          </div>
          <div class="greygoose-banner__img">
            <img src="/greygoose/pachet.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="greygoose-slug">
      <img src="/greygoose/bgSlug.png" alt="" class="desk">
      <img src="/greygoose/bgSlugMob.png" alt="" class="mob">
      <div class="container">
        <p>{{ $t("greygoose.slug") }}</p>
      </div>
    </div>
    <div class="greygoose-product">
      <div class="container">
        <h2 class="black">{{ $t("greygoose.product.title") }}</h2>
        <ProductSlider :items="products"/>
      </div>
    </div>
    <div class="greygoose-addres">
      <div class="container">
        <div class="greygoose-addres__title">
          <h2 v-html="$t('greygoose.address.title')"></h2>
          <p class="desk" v-html="$t('greygoose.address.subtitle')"></p>
        </div>
        <div class="greygoose-addres__info">
          <div v-for="(addres, i) in address" :key="i" class="greygoose-addres__item">
            <div class="icon desk">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
                <path d="M8 0C3.58174 0 0 3.59016 0 8.01882C0 14.9213 5.44963 20.1473 7.35314 21.7623C7.72652 22.0791 8.27348 22.0791 8.64686 21.7623C10.5504 20.1473 16 14.9213 16 8.01882C16 3.59016 12.4183 0 8 0ZM8 13.1586C5.16799 13.1586 2.87231 10.8575 2.87231 8.01882C2.87231 5.18014 5.16799 2.87907 8 2.87907C10.832 2.87907 13.1277 5.18014 13.1277 8.01882C13.1277 10.8575 10.832 13.1586 8 13.1586Z" fill="#A1DAF8"/>
              </svg>
            </div>
            <div class="item ">
              <div class="item-title">
                <div class="icon mob">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 16 22" fill="none">
                <path d="M8 0C3.58174 0 0 3.59016 0 8.01882C0 14.9213 5.44963 20.1473 7.35314 21.7623C7.72652 22.0791 8.27348 22.0791 8.64686 21.7623C10.5504 20.1473 16 14.9213 16 8.01882C16 3.59016 12.4183 0 8 0ZM8 13.1586C5.16799 13.1586 2.87231 10.8575 2.87231 8.01882C2.87231 5.18014 5.16799 2.87907 8 2.87907C10.832 2.87907 13.1277 5.18014 13.1277 8.01882C13.1277 10.8575 10.832 13.1586 8 13.1586Z" fill="#A1DAF8"/>
              </svg>
            </div>
                <h3>{{ addres.title }}</h3>
              </div>
              <p v-for="(item, i) in addres.items" :key="i">
                <span>{{ item }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="284" height="1" viewBox="0 0 284 1" fill="none">
                  <path d="M0.5 0.5H283" stroke="#A1DAF8" stroke-linecap="round" stroke-dasharray="4 4"/>
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="greygoose-slik desk">
      <div class="container">
        <p>{{ $t("greygoose.slik") }}</p>
        <img
          src="/glo/21.png"
          alt=""
        />
      </div>
    </div> -->
  </main>
</template>

<script>
// import axios from "@/plugins/axios"
// import {
//   getQuestionnaire,
//   questionnairesCheckUser,
//   questionnairesEndTest,
//   questionnaireResponse,
// } from "@/api/questionnaire"

export default {
  name: "DewarsPage",
  components: {
    ProductSlider: () => import("@/components/greygoose/ProductSlider.vue"),
    // StikSlider: () => import("@/components/glo/StikSlider.vue")
  },
  data() {
    return {
      address: [],
      isImg: null,
      info: null,
      products: [],
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef
    },
  },
  created() {
    this.address = this.$t('greygoose.address.addresses')
    this.products = this.$t('greygoose.product.items')
    // axios.get(`/promos/83?populate[localizations]=%2A`).then((res) => {
    //   this.info = res.data.data
    //   if (this.info && res?.data?.data?.attributes?.localizations?.data?.length) {
    //     this.info.attributes.localizations = this.info.attributes?.localizations.data[0]
    //   }
    // })
  },
  mounted() {
    this.Timer()
    // this.isMob = window.innerWidth < 500
    // window.addEventListener("resize", function () {
    //   this.isMob = window.innerWidth < 500
    // })
    // this.sl = "display: flex"
    // let routePath = this.$route.path.split("-")
    // if (routePath.length >= 2) {
    //   let id = this.slugs.find((e) => e.slug == routePath[1]).id
    //   getQuestionnaire(id).then((res) => {
    //     this.survey = res
    //   })
    // } else {
    //   getQuestionnaire(1).then((res) => {
    //     this.survey = res
    //   })
    // }
    // if (this.$route.query.q) {
    //   let q = this.$route.query.q.split(" ")
    //   this.user = {
    //     card: q[0],
    //     phone: q[1],
    //     test: 1,
    //   }
    //   questionnairesCheckUser(this.user).then((res) => {
    //     if (!res.isTest) {
    //       this.isShowPreview = false
    //       this.isShowSucces = true
    //     }
    //   })
    // } else {
    //   this.$router.push("/")
    // }
  },
  watch: {
    // progress: {
    //   handler() {
    //     this.error = false
    //     window.scrollTo(0, 0, "smooth")
    //   },
    // },
    // selected: {
    //   deep: true,
    //   handler() {
    //     this.error = false
    //   },
    // },
    // "selectedList.length": {
    //   handler() {
    //     this.error = false
    //   },
    // },
  },
  methods: {
    Timer() {
      var t = Date.parse(new Date("2024-12-31")) - Date.parse(new Date())
      this.timer.seconds = Math.floor((t / 1000) % 60)
      this.timer.minutes = Math.floor((t / 1000 / 60) % 60)
      this.timer.hours = Math.floor((t / (1000 * 60 * 60)) % 24)
      this.timer.days = Math.floor(t / (1000 * 60 * 60 * 24))
      setTimeout(() => this.Timer(), 1000)
    },
    getProduct() {
      console.log(this.$i18n.locale)

      let arr = []
      const prod = this.$i18n.locale === "ru" ? this.product : this.productkz
      for (let i = 0; i < 10; i++) {
        arr = [...arr, ...prod]
      }
      return arr
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    rerender() {
      // if (this.isMob) {
      //   this.isSlider = false
      //   setTimeout(() => {
      //     this.isSlider = true
      //   }, 10)
      // }
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
    // showQuestion() {
    //   this.isShowPreview = false
    //   this.isShowQuestion = true
    // },
    // showEnd() {
    //   this.isShowQuestion = false
    //   this.isShowEnd = true
    // },
    // showFalse() {
    //   this.isShowQuestion = false
    //   this.isShowFalse = true
    // },
    // nextPage() {
    //   if (this.isManyAnswers ? !this.selectedList.length : !this.selected) {
    //     this.error = true
    //     window.scrollTo(0, 0, "smooth")
    //     return
    //   }
    //   let questionnaire = {
    //     phone: this.user?.phone,
    //     card: this.user?.card,
    //     test_id: 1,
    //     question_id: this.survey.question[this.progress - 1].id,
    //   }
    //   if (this.isManyAnswers) {
    //     questionnaire.answers = this.selectedList.map((e) => e.label)
    //     questionnaireResponse(questionnaire)
    //     if (this.selectedList.length === 1 && this.selectedList.find((e) => e.label === "Сигареты")) {
    //       this.progress = this.selectedList.find((e) => e.label === "Сигареты")?.next_order
    //     } else {
    //       this.progress = this.selectedList.filter((e) => e.label !== "Сигареты")[0]?.next_order
    //     }
    //     this.selectedList = []
    //     this.isManyAnswers = false
    //   } else {
    //     questionnaire.answers = this.selected.label
    //     questionnaireResponse(questionnaire)
    //     if (this.selected?.end) {
    //       this.selected = null
    //       if (this.progress == 1 || this.progress == 2) {
    //         this.showFalse()
    //         return
    //       }
    //       this.showEnd()
    //       return
    //     }
    //     if (this.selected?.next_order) {
    //       if (this.survey.question.findIndex((e) => e.order_id === this.selected.next_order)) {
    //         this.progress =
    //           this.survey.question.findIndex((e) => e.order_id === this.selected.next_order) + 1
    //       }
    //       this.selected = null
    //       return
    //     }
    //     if (this.progress != 7) {
    //       this.progress++
    //     } else {
    //       this.showEnd()
    //     }
    //   }
    //   this.selected = null
    //   this.selectedList = []
    //   this.isManyAnswers = false
    // },
    // getImage(image) {
    //   const urlImg = image
    //   if (urlImg) {
    //     return this.$helpers.getAbsolutePath(urlImg)
    //   }
    // },
    // getActive(item) {
    //   if (this.isManyAnswers) {
    //     return this.selectedList.findIndex((e) => e.id === item.id) != -1 ? true : false
    //   } else {
    //     return this.selected ? item.id == this.selected.id : false
    //   }
    // },
    // selectedItem(survey, item) {
    //   if (survey.mani_answers) {
    //     this.isManyAnswers = true
    //     // if (
    //     //   this.selectedList.length &&
    //     //   this.selectedList.find((e) => e.next_order !== item.next_order)
    //     // ) {
    //     //   this.selectedList = []
    //     // }
    //     if (this.selectedList.findIndex((e) => e.id === item.id) == -1) {
    //       this.selectedList.push(item)
    //     } else {
    //       this.selectedList.splice(
    //         this.selectedList.findIndex((e) => e.id === item.id),
    //         1,
    //       )
    //     }
    //     return
    //   }
    //   if (this.selected?.id === item.id) {
    //     this.selected = null
    //   } else {
    //     this.selected = item
    //   }
    // },
    // finishedSurvey() {
    //   questionnairesEndTest(this.user).then((res) => {
    //     if (res.end) {
    //       window.location.href = this.survey.link
    //       return
    //     }
    //     if (res.error) {
    //       alert(res.error)
    //     }
    //   })
    // },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
